<template>
    <main>
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
    </Transition>
    <div class="p-5">
        <button class="btn-list mt-20" @click="toggleModal(),$store.state.locationId=null">{{$t ('AddLocationMsg')}}</button>	
        <ul
  class="mb-5 flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row"
  role="tablist"
  data-te-nav-ref>
  <li role="presentation">
    <a
      href="#tabs-home2"
      class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-success data-[te-nav-active]:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      data-te-target="#tabs-home2"
      data-te-nav-active
      role="tab"
      aria-controls="tabs-home2"
      aria-selected="true"
      >Pending</a
    >
  </li>
  <li role="presentation">
    <a
      href="#tabs-profile2"
      class="focus:border-transparen my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-success data-[te-nav-active]:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      data-te-target="#tabs-profile2"
      role="tab"
      aria-controls="tabs-profile2"
      aria-selected="false"
      >Approuved</a
    >
  </li>
 
 
        </ul>
    </div>
<div class="mb-6" v-if="status">
  <div
    class="hidden opacity-0  transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
    id="tabs-home2"
    role="tabpanel"
    aria-labelledby="tabs-home-tab2"
    data-te-tab-active>
    <div class="p-8 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
        <table class="w-full" id="datatable">    
                <thead class="bg-black">
                    <tr>
                        <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                            #
                        </th>
                        <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                            Evenement
                        </th>
                        <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                            {{ $t('clientMsg') }}
                        </th>
                        <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                            Date de livraison
                        </th>
                        
                        <!--<th class="px-6 py-2 text-xs text-white capitalize">
                            amount
                        </th>
                        
                        <th class="px-6 py-2 text-xs text-white capitalize">
                            status
                        </th>   -->                          
                        <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-300">

                    <tr v-for="location in pendingloc" :key="location.id" class="text-center whitespace-nowrap">
                        <th scope="row" class="px-6 py-4 text-sm text-gray-500">
                            {{ location.id }}
                        </th>
                        <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{location.events.name}}
                            </div>
                        </td>
                        <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{location.client.name}}
                            </div>
                        </td>
                        <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{(location.nb_days.substr(0, 10))}}
                            </div>
                        </td>
                        <!-- <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{location.amount}}
                            </div>
                        </td>
                        -->
                        
                        <td class="flex justify-center gap-8 px-6 py-4 whitespace-nowrap">
                            
                            <button class="inline-block text-center"  @click="editLocation(location,location.id),toggleModal()">
                                
                                <router-link :to="{name:'view',params:{id:location.id}}" class="nav-link collapsed  text-black" 
                                        aria-expanded="true" aria-controls="collapseStock"><font-awesome-icon icon="fa-solid fa-file-invoice" />view
                                </router-link>
                                    

                            </button>
                            <button class="inline-block text-center" @click="deleteLocation(location.id)">
                                <span class="material-icons">delete</span>
                            </button>    
                                
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
  </div>
  
  <div
    class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
    id="tabs-profile2"
    role="tabpanel"
    aria-labelledby="tabs-profile-tab2">
    <div class="p-8  relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full" id="datatables">
                <thead class="bg-black">
                    <tr>
                        <th class="px-6 py-2 text-xs text-white capitalize">
                            #
                        </th>
                        <th class="px-6 py-2 text-xs text-white capitalize">
                            Evenement
                        </th>
                        <th class="px-6 py-2 text-xs text-white capitalize">
                            {{ $t('clientMsg') }}
                        </th>
                        <th class="px-6 py-2 text-xs text-white capitalize">
                        status
                        </th>
                        <th class="px-6 py-2 text-xs text-white capitalize">
                            Date de livraison
                        </th>
                        
                        <!--<th class="px-6 py-2 text-xs text-white capitalize">
                            amount
                        </th>
                        
                        <th class="px-6 py-2 text-xs text-white capitalize">
                            status
                        </th>   -->                          
                        <th class="px-6 py-2 text-xs text-white capitalize">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-300">
                    <tr v-for="location in sortieloc" :key="location.id" class="text-center whitespace-nowrap">
                        <th class="px-6 py-4 text-sm text-gray-500">
                            {{ location.id }}
                        </th>
                        <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{location.events.name}}
                            </div>
                        </td>
                        <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{location.client.name}}
                            </div>
                        </td>
                        <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{location.status}}
                            </div>
                        </td>
                        <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{(location.nb_days.substr(0, 10))}}
                            </div>
                        </td>
                        <!-- <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                                {{location.amount}}
                            </div>
                        </td>
                        -->
                        
                        <td class="flex justify-center gap-8 px-6 py-4">
                            
                            <button class="inline-block text-center"  @click="editLocation(location,location.id),toggleModal()">
                                
                                <router-link :to="{name:'view',params:{id:location.id}}" class="nav-link collapsed  text-black" 
                                        aria-expanded="true" aria-controls="collapseStock"><font-awesome-icon icon="fa-solid fa-file-invoice" />view
                                </router-link>
                            </button>
                            <!--<button class="inline-block text-center" @click="deleteLocation(location.id)">
                                <span class="material-icons">delete</span>
                            </button>-->
                            
                                <router-link v-if="location.status=='done'" :to="{name:'fiche',params:{id:location.id}}" class="nav-link collapsed  text-black" 
                                    aria-expanded="true" aria-controls="collapseStock"><font-awesome-icon icon="fa-solid fa-file-invoice" />FIche
                                </router-link> 
                                <router-link v-if="location.status=='done'"  :to="{name:'returnid',params:{id:location.id}}" class="nav-link collapsed  text-black" 
                                    aria-expanded="true" aria-controls="collapseStock"><font-awesome-icon icon="fa-solid fa-file-invoice" />Retour
                            </router-link>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

    </div>
    
</div>
<div v-else>              
    <tableHolder class="w-full"/>
</div>


</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../api.js'
import result from '@/mixins/result';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {

data () {     
    return {
        locations:[],
        status:null
    }
},
mixins:[result],
components:{
    'formData':require('./locationForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},
watch:{
    locations(val) {
          console.log(val)
          $('#datatable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#datatable').DataTable()
          });
          $('#datatables').DataTable().destroy();
          this.$nextTick(()=> {
            $('#datatables').DataTable()
          });
        },
},
mounted(){
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    // this.WRAP_MODAl();
    this.$router.push('/location_form')

    // this.$store.state.locationId=null;
},
fetchData() {
        api.get("/location")
        .then(resp => {
            this.locations = resp.data
            console.log(this.locations);
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteLocation(id){
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
        api.delete("location/" + id)
        .then(resp => {
            this.showDeleteConfirmation()
            //this.categories = resp.data.data 
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }}
    )},

    editLocation(loc,id){
        this.$store.state.locationId=id
        this.$store.state.location=loc
    
    },
    gotoreturn(id)
    {

    }

},
computed:{
...mapState(['wrapModal','locationId','location']),

pendingloc()
{
    return this.locations.filter((item)=>item.status=='pending')
},
approuvedloc()
{
    return this.locations.filter((item)=>item.status=='approved')

},

sortieloc()
{
    return this.locations.filter((item)=>item.status=='done' || item.status=='approved')
}

// locations(){
//         return  this.$store.state.location
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99; 
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    transform: translateX(700px);
    position: relative;
    z-index: 99; 
}
</style>