<template>
    <div class="w-full">
        <sortie/>
    </div>
</template>

<script>
    export default {
        
        components:{
            'sortie':require('@/components/articles/sortiearticlesList.vue').default
        }
    }
</script>

<style lang="scss" scoped>

</style>