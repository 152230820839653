<template>
    <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
    <Loading v-show="loading" class="h-full"/>
      <h1 class=" text-3xl flex justify-center mb-3">{{ $t('categoryMsg') }}</h1>
      <form action="" @submit.prevent="saveInformation" ref="categoryForm"> 
          <div class="p-3">
            <div class="relative z-0">
                <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
            </div>
            <div>{{ errors?.name }}</div>
          </div>

          <div class=" p-3">
            <label for="">{{ $t('stockMsg') }} :</label>
            <select name="" required id="" v-model="form.stock_id" class="select-buja">
              <option v-for="stock in stocks"  :key="stock.id" :value="stock.id">
                {{ stock.name }}
              </option>
            </select>            
          </div>

          <div class="flex gap-8 mt-5">
              <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
              <button type="reset" class=" btn-red-empty">{{ $t('btnEmpty') }}</button>
            </div>
            <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
        </form>
  </div>   
</template>

<script>

import { mapGetters, mapMutations,mapState } from 'vuex';
import api from '../../../api.js'
import result from '../../mixins/result'
import i18n from '@/plugins/i18n';
export default{
      mixins: [result],      
      data(){       
          return{
          form:{name:"",stock_id:""},
          loading:null,
          stocks:[],
          category:[],
          catid:this.$store.state.categoryId,
          saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
          }
      },
      components:{
          'Loading':require('../Global/Loading.vue').default
      },

      mounted()
      {

         this.getstoks()
         
         if(this.catid==null){
            this.form={};
            this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }else{
              this.form=this.$store.state.category;
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
            }
              console.log(this.catid)
      },
      computed:{
      ...mapState(['categoryId','category']),
  },
/*  watch:{
        "catid"(a){
          console.log(a)
          if(this.$store.state.categoryId==null){
            this.form={};
              this.saveEditBtn="Save"
            }else{
              this.form=this.$store.state.category;
              this.saveEditBtn="Edit"
            }
              console.log(a)
        },
        "form.name"(val)
        {
          console.log(this.form.name)
          
        }
      },*/

      methods:{
          
              ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),

              toggleModal(){
                  this.WRAP_MODAl();  
                  this.$store.state.categoryId=null;
                  this.$emit('close')
              },
              SaveData(){
                  this.loading=true;
              },
              getstoks() {
                  api.get("/stock")
                  .then(resp => {
                      this.stocks = resp.data.data
                  })
                  .catch(err => {
                      console.error(err)
                  })
            },
          saveInformation() {
           
          this.loading = true;
          if (this.form["name","stock_id"] == "") return;
          console.log('hi')

          if (this.categoryId == null) {
              api.post(
                  "/category",
                  this.form
              )
                  .then((resp) => {
                    this.loading = false;
                    this.category = resp.data;
                    this.successAllert()
                    this.form = { name:"",stock_id:""};
                   
                  })
                  .catch((err) => {
                    this.loading = false;
                      console.error(err.response.data.errors);
                      this.errors = err.response.data.errors;
                      this.errorAlert()
                  });
          } else {
              api.patch(
                  "/category/" + this.categoryId,
                  this.form)
                  .then((resp) => {
                      this.successAllert()
                      this.category = resp.data;
                      this.loading = false;
                      this.$store.state.wrapModal=null;
                  })
                  .catch((err) => {
                      console.error(err.response.data.errors);
                      this.errors = err.response.data.errors;
                      this.errorAlert()
                  });
          }
      }
      },     
  }
</script>

<style lang="scss" scoped>
// .FormCl{
//   @media (max-width: 1024px) {
// 			margin: auto;
// 		}
// }
</style>