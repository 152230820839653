<template>
    <main>
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
        </Transition>
    <div class="p-5">
        <button class="p-2 bg-green-500 rounded-sm w-28" @click="toggleModal(),$store.state.locationId=null">Add Location</button>			

        <!-- table -->

        <div class="mx-auto">
        <div class="flex flex-col">
            <div class="w-full">
                <div class="p-8 border-b border-gray-200 shadow">
                    <table class="divide-y divide-gray-300 w-full" id="datatable">
                        <thead class="bg-black">
                            <tr>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    #
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    Date livraison
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    client
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    Condition de travail
                                </th>
                                <!--<th class="px-6 py-2 text-xs text-white capitalize">
                                    amount
                                </th>
                                -->
        
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    status
                                </th>                             
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-300">
                            <tr v-for="location in locations" :key="location.id" class="text-center whitespace-nowrap">
                                <th class="px-6 py-4 text-sm text-gray-500">
                                    {{ location.id }}
                                </th>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{(location.nb_days.substr(0, 10))}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{location.client.name}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{location.detail}}
                                    </div>
                                </td>
                               <!-- <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{location.amount}}
                                    </div>
                                </td>
                                -->
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{location.status}}
                                    </div>
                                </td>
                                <td class="flex justify-center gap-8 px-6 py-4">
                                    <button class="inline-block text-center " @click="editLocation(location,location.id),toggleModal()">
                                        <span class="material-icons">edit_square</span>                                           
                                    </button>
                                    <button class="inline-block text-center" @click="deleteLocation(location.id)">
                                        <span class="material-icons">delete</span>
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../api.js'
import result from '@/mixins/result';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {

data () {     
    return {
        locations:[]
    }
},
mixins:[result],
components:{
    'formData':require('./sortiearticles.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,

},
watch:{
    locations(val) {
          console.log(val)
          $('#datatable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#datatable').DataTable()
          });
        },
},
mounted(){
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
    // this.$store.state.locationId=null;
},
fetchData() {
        api.get("/location")
        .then(resp => {
            this.locations = resp.data
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteLocation(id){
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
        api.delete("location/" + id)
        .then(resp => {
            this.showDeleteConfirmation()
            //this.categories = resp.data.data 
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }}
    )},

    editLocation(loc,id){
        this.$store.state.locationId=id
        this.$store.state.location=loc
    
    }
},
computed:{
...mapState(['wrapModal','locationId','location']),
// locations(){
//         return  this.$store.state.location
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99; 
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    transform: translateX(700px);
    position: relative;
    z-index: 99; 
}
</style>