<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3 ">{{$t ('EventMsg') }}</h1>
        <form action="" @submit.prevent="saveInformation"> 

          <div class="p-3">
            <div class="relative z-0">
                <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
            </div>
            <div>{{ errors?.name }}</div>
          </div>
          <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('categoryMsg') }}</label>
              <select name="" id="" v-model="form.event_category_id" required class="select-buja">
                <option v-for="category in categories"  :key="category.id" :value="category.id" class="" selected>
                  {{ category.name }}
                </option>
              </select>              
            </div>
            <div class=" p-3">
              <label for="" class="text-gray-500">Date</label>
              <input type="date" required placeholder="Date" v-model="form.date"
               class="input-buja"> 
               <div>{{ errors?.date }}</div> 
            </div>
            <div class=" p-3">
              <label for="">{{$t ('startTimeMsg') }}</label>
              <input type="time" placeholder="Start time" v-model="form.start_time" required
               class="input-buja"> 
               <div>{{ errors?.start_time }}</div> 
            </div>
            <div class=" p-3">
              <label for="" class="text-gray-500">{{$t ('endTimeMsg') }}</label>
              <input type="time" placeholder="End time" v-model="form.end_time" required
               class="input-buja"> 
               <div>{{ errors?.end_time }}</div> 
            </div>
            <div class=" p-3">
              <label for="" class="text-gray-500">image:</label>
              <input @change="onFileSelected" type="file"  name="" id="" class="py-2 px-1 w-full bg-[#141625] "/>
            </div>
            <div class=" p-3">
              <label for="" class="text-gray-500">{{$t ('descriptionMsg') }}</label>
              <textarea placeholder="" v-model="form.description" required
               class="input-buja"> 
               </textarea>
               <div>{{ errors?.description }}</div> 
            </div>
            <div class="p-3">
              <div class="relative z-0">
                  <input type="text" required id="default_standard" v-model="form.artists" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('artistMsg') }} </label>
              </div>
              <div>{{ errors?.artists }}</div>
            </div>
            <div class="p-3">
              <div class="relative z-0">
                  <input type="text" required id="default_standard" v-model="form.address" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('addressMsg') }} </label>
              </div>
              <div>{{ errors?.address }}</div>
            </div>
            <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('organizerMsg') }}</label>
              <select name="" id="" v-model="form.organiser_id" class="select-buja">
                <option v-for="organizer in organizers"  :key="organizer.id" :value="organizer.id">
                  {{ organizer.name }}
                  {{ organizer.name }}
                </option>
              </select>            
            </div>
                 
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">Empty</button>
              </div>
              <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
          </form>
         
    </div>   
  </template>
  
  <script>
  
  import { mapMutations,mapState } from 'vuex';
  import {uid} from 'uid'
  import api from '../../../../api.js'
  import result from '../../../mixins/result';
  
  export default{
    mixins:[result],
        data(){
            return{
            form:{
               uuid:uid(),
                event_category_id:"",
                name:"",
                start_time:"",
                end_time:"",
                organiser_id:"",
                artists:'',
                address:"",
                description:"",
                date:new Date().toISOString().slice(0,10),
                image:"",
            },
            loading:null,
            categories:[],
            organizers:[],
            events:[],
            errors:[],
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../../Global/Loading.vue').default
        },

        mounted()
        {
           this.getCategory()
           this.getOrganizer()
           if(this.$store.state.eventId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.event;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
  
  
        },
        computed:{
        ...mapState(['eventId','event'])
    },
  
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();   
                    this.$emit('dashboardClose')
                },
                SaveData(){
                    this.loading=true;
                },
                getCategory() {
                    api.get("/event-category")
                    .then(resp => {
                        this.categories = resp.data
                        console.log(this.categories)
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
              getOrganizer() {
                    api.get("/organiser")
                    .then(resp => {
                        this.organizers = resp.data
                       
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
            saveInformation() {            
            this.loading = true;
             // const fd= new FormData();
                    // fd.append('image',this.form.image,this.form.image.name);
                    // this.form.image=fd;

            if (this.form["date","start_time","name","event_category_id","organiser_id"] == "") return;
            console.log(this.form.uuid)

                      const EventForm = new FormData()
                       EventForm.append("event_category_id", this.form.event_category_id);
                       EventForm.append("organiser_id", this.form.organiser_id);
                       EventForm.append("name", this.form.name);
                       EventForm.append("uuid", this.form.uuid);
                       EventForm.append("start_time", this.form.start_time);
                       EventForm.append("end_time", this.form.end_time);
                       EventForm.append("date", this.form.date);
                       EventForm.append("description", this.form.description);
                       EventForm.append("artists", this.form.artists);
                       EventForm.append("address", this.form.address);
                       EventForm.append("image", this.form.image);
  
            if (this.eventId == null) {
                api.post(
                    "/event",
                    EventForm
                )
                    .then((resp) => {
                        this.loading = false;
                        this.events = resp.data;
                        this.form={event_category_id:"",artists:"",address:"",name:"",start_time:"",end_time:"",date:"",description:"",image:"",organiser_id:""},
                        this.successAllert()
                    })
                    .catch((err) => {
                      this.loading = false;
                      this.errorAlert()
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        
                    });
            } else {
                api.patch(
                    "/event/" + this.eventId,
                    this.form)
                    .then((resp) => {
                        this.events = resp.data;
                        this.loading = false;
                        this.successAllert()
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                      this.loading = false;
                      this.errorAlert()
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        
                    });
  
            }
  
        },
        onFileSelected(event){
                    console.log(event);
                    this.form.image=event.target.files[0]
                }



        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }

  </style>