<template>
    <main>
        <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
        </Transition>
      <div class="p-5">
        <button class="btn-list mt-20" @click="toggleModal">{{ $t ('AddMsg') }} Article</button>			
        <!-- table -->
        <div v-if="status">
            <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full " id="dataTable">
                    <thead class="bg-black">
                        <tr>
                            <th scope="col"  class="px-6 py-2 text-xs text-white">
                                #
                            </th>
                            <th scope="col"  class="px-6 py-2 text-xs text-white">
                                UID
                            </th> 
                            <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('nameMsg') }}
                            </th>
                            <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">                           
                                {{ $t('serialNumberMsg') }}
                            </th>
                            <th class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('specificationMsg') }}
                            </th>
                            <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                
                                {{ $t('Section') }}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                
                                {{ $t('categoryMsg') }}
                            </th>
                            <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('valueMsg') }}
                            </th>
                            <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('QuantityMsg') }}
                            </th>
                        <!-- <th class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('MinQuantityMsg') }}
                            </th>
                            <th class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('LocationPriceMsg') }}
                            </th>  --> 
                                                    
                            <th scope="col"  class="px-6 py-2 text-xs text-white">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-300">
                        <tr v-for="article in articles" :key="article.id" class=" hover:bg-green-200 hover:cursor-pointer text-center whitespace-nowrap hover:text-buja-primary">
                            <th scope="row" class="px-6 py-4 text-sm text-gray-500">
                                {{ article.id }}
                            </th>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.uuid}}
                                </div>
                            </td> 
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.name}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.serial_no}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.specification}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.section}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.category.name}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.value}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.quantity}}
                                </div>
                            </td>
                            <!--<td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.quantity_min}}
                                </div>
                            </td>-->
                        <!-- <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{article.price_location}}
                                </div>
                            </td>-->
                            <td class="flex justify-center gap-8 px-6 py-4 whitespace-nowrap">
                                <button class="inline-block text-center " @click="editArticle(article,article.id),toggleModal1()">
                                    <span class="material-icons">edit_square</span>                                           
                                </button>
                                <button class="inline-block text-center" @click="deleteArticle(article.id)">
                                    <span class="material-icons">delete</span>
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>              
            <tableHolder/>
        </div>    

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import result from '../../mixins/result';
export default {
    mixins:[result],

data () {     
    return {
        articles:[],
        status:null
    }
},
components:{
    'formData':require('./ArticleForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},
watch:{
    articles(val) {
          console.log(val)
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},
mounted(){
    
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    // this.WRAP_MODAl();
    this.$router.push('/article_form')
    this.$store.state.articleId=null
},

    toggleModal1()
    {
        this.$router.push('/article_form')
    
    },
fetchData() {
        api.get("/article")
        .then(resp => {
            this.articles = resp.data
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteArticle(id) {
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
            api.delete("article/" + id)
        .then(resp => {
            //this.articles = resp.data.data
            this.fetchData()
            this.showDeleteConfirmation()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
        
    }}
    )
        
        
    },

    editArticle(art,id){
        this.$store.state.articleId=id
        this.$store.state.article=art
    
    }
},
computed:{
...mapState(['wrapModal','articleId','article']),
// articles(){
//         return  this.$store.state.article
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px); 
}

</style>