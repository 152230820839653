<template>
  <!-- <div :class="`${loading ? 'fixed p-[56px] bg-[rgb(20,22,37)] max-w-[700px] h-screen text-white ' : 'buja-form-content'}`" ref="form-content"> -->
  <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
     <!-- <div class="buja-form-content FormCl" ref="form-content"> -->
    <Loading v-show="loading" class="h-full"/>
      <h1 class=" text-3xl flex justify-center mb-3 ">{{ $t ('outputArticleMsg') }}</h1>
      <form action="" @submit.prevent="saveInformation" ref="locationForm">
      <div class="  max-w-sm rounded overflow-hidden shadow-lg">
       <div>
          <div class=" p-3">
            <label for="">{{ $t ('receiverNameMsg') }}</label>
            <select name="" id="" required v-model="form.client_id" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
              <option v-for="client in clients"  :key="client.id" :value="client.id" class="bg-[#141625] hover:bg-green-300">
                {{ client.name }}
              </option>
            </select>              
          </div>
          <div class=" p-3">
            <label for="">{{ $t ('EventMsg') }} </label>
            <select name="" id="" required v-model="form.events_id" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
              <option v-for="event in events"  :key="event.id" :value="event.id" class="bg-[#141625] hover:bg-green-300">
                {{ event.name }}
              </option>
            </select>              
          </div>
      </div>
      <div>
      
          <div class=" p-3">
              <label for="">{{ $t ('delivaryDateMsg') }} :</label>
            <input type="date" required placeholder="Amount" v-model="form.nb_days"
             class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
             <div>{{ errors?.amount }}</div> 
          </div>
       

          <div class=" p-3">
            <label for="">{{ $t ('DetailMsg') }}</label>
            <input type="text" required :placeholder="$t ('DetailMsg') " v-model="form.detail"
             class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
            
             <div>{{ errors?.detail }}</div> 
          </div>
          
          </div>
          
          <div class="p-3">
            <label for="">{{ $t ('Categories') }}</label>
            <select name="" id=""  @keypress.enter="change" v-model="category" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
              <option class="hover:bg-green-300" v-for="category in categories" :key="category.id" :value="category"> {{ category.name }} </option>
              
            </select> 
          </div>
      </div>
      <div class="pt-5 max-w-sm rounded overflow-hidden shadow-lg">
        <h5>{{$t ('EquipmentMsg')}}</h5>
        <div class="flex justify-between">
          <div class=" p-3">
            <label for="">{{ $t ('designationMsg') }} :</label>
            <select name=""  id="" v-model="form.article_id" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
              <option v-for="article in filtereditem" :key="article.id" :value="article" class="hover:bg-green-300">
                {{ article.name}}
              </option>
            </select>              
          </div> 
          <div class="p-3">
            <label for="">{{ $t ('QuantityMsg') }}</label>
            <input type="number"  :placeholder="$t ('QuantityMsg')" v-model="form.amount"
             class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
             <div>{{ errors?.amount }}</div> 
          </div>
          <div>
            
              <span @click="addNewDetail" class="cursor-pointer border-2 border-white text-white  font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1">{{$t ('AddMsg')}}</span>
            
          </div>
        </div>
        <div class=" p-3"  v-for="item in autres" :key="item.id">
            <div class="flex justify-between align-baseline">
                <div class="">
                  <h6>{{$t('nameMsg')}}</h6>
                  <h6>{{$t ('QuantityMsg')}}</h6>
                </div>
                <div class="">
                  <h6>{{ item.name }}</h6>
                  <h6>{{ item.quantity }}</h6> 
                </div>
                <button @click="deleteDetail(item.id)" class="cursor-pointer outline-none border-none text-white mr-[8px] font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1 mt-2">X</button>
              </div>
         </div>
      </div>
      <div class="flex gap-8 mt-8">
                <button type="submit" class=" rounded-sm p-3 bg-green-600 hover:bg-green-400">{{saveEditBtn}}</button>
                <button type="reset" class=" rounded-sm p-3 bg-red-600 hover:bg-red-400">{{ $t ('btnEmpty') }}</button>
                <div  @click="toggleModal" class="rounded-sm p-3 bg-gray-600  cursor-pointer hover:bg-gray-400 justify-end">{{ $t ('btnClose') }}</div>
       </div>
      </form>
      
       
  </div>   
</template>

<script>

import { mapMutations,mapState } from 'vuex';
import {uid} from 'uid'
import api from '../../../api.js'
import result from '../../mixins/result';
export default{
  mixins:[result],
      data(){
          return{
          form:{
              status:'',
              client_id:'',
              nb_days: new Date().toISOString().slice(0, 10),
              articles:'',
              detail:'',
              article_id:'',
              amount:'',
              events_id:''
          },
          loading:null,
          autres:[],
          clients:[],
          articles:[],
          errors:[],
          events:[],
          category:'',
          categories:[],
          saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
          }
      },
      components:{
          'Loading':require('../Global/Loading.vue').default
      },
      mounted()
      {
         this.getclient()
         this.getevents()
         this.getarticles()
         this.getCategory()
         if(this.$store.state.articleId==null){
            this.form={};
            this.form.uuid=uid(),
            this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }else{
              this.form=this.$store.state.article;
              this.form.category_id=this.$store.state.article.category_id;
              console.log(this.$store.state.article.category_id)
              console.log(this.form.category_id)
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
            }
      },
      computed:{
        filtereditem()
      {
        return this.change(this.articles, this.category.id)
      },
      ...mapState(['articleId','article'])
      
      },
      methods:{
          
              ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),

              toggleModal(){
                  this.WRAP_MODAl();
                  this.$emit('close')   
              },
              change(articles,id)
              {
                if(Array.isArray(articles) ){
                return articles.filter((item)=> item.category_id==id
                        )} 
                console.log(this.articles)
                return articles;

              },
              SaveData(){
                  this.loading=true;
              },
              getarticles() {
                  api.get("/article")
                  .then(resp => {
                      this.articles = resp.data
                      console.log(this.articles)
                  })
                  .catch(err => {
                      console.error(err)
                  })
            },
              getclient() {
                  api.get("/client")
                  .then(resp => {
                      this.clients = resp.data
                      console.log(this.clients)
                  })
                  .catch(err => {
                      console.error(err)
                  })
            },
            getCategory() {
              
                    api.get("/category")
                    .then(resp => {
                        this.categories = resp.data
                        console.log(this.categories)
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
            getevents()
            {
              api.get("/event")
              .then(resp => {
                  this.events = resp.data
                  console.log(this.events)
              })
              .catch(err => {
                  console.error(err)
              })

            },
          saveInformation()
          {            
          this.loading = true;
         // console.log(this.form.uuid);
          if (this.form == {}) return;
          // console.log('please complete required fields')
          this.form.articles=this.autres

          if (this.locationId == null) {
              api.post(
                "/location",
                this.form
              )
                .then((resp) => {
                  this.loading = false;
                  this.locations = resp.data;
                  this.successAllert()
                  this.form={ nb_days: new Date().toISOString().slice(0, 10), detail: "", service_id: "", articles: "", amount: "", client_id: "", status: "" }
                })
                .catch((err) => {
                  this.loading = false;
                  console.error(err.response.data.errors);
                  this.errors = err.response.data.errors;
                  this.errorAlert()
                });
            } else {
              api.patch(
                "/location/" + this.locationId,
                this.form)
                .then((resp) => {
                  this.locations = resp.data;
                  this.loading = false;
                  this.successAllert()
                  this.$store.state.wrapModal = null;
                })
                .catch((err) => {
                  console.error(err.response.data.errors);
                  this.errors = err.response.data.errors;
                  this.errorAlert()
                });

            }

         },
               addNewDetail() {
               let result= this.autres.filter((item)=>item.id==this.form.article_id.id);
               let result2= this.articles.find((item) => item.id == this.form.article_id.id)
                     
                         console.log(result2)
               if(result.length==0)
               {
               if(this.form.amount>result2.quantity)
               {
                 alert('quantité pas disponible')
               }
               else{
                 this.autres.push({
                 
                 id: this.form.article_id.id,
                 name:this.form.article_id.name,
                 quantity: this.form.amount,
               });
               
               }
               console.log(this.autres)    
               }
               },
               
               deleteDetail(id) {
                 this.autres = this.autres.filter((item) => item.id !== id);
               },
               

      },
     
  }
</script>

<style lang="scss" scoped>
// .FormCl{
//   @media (max-width: 1024px) {
// 			margin: auto;
// 		}
// }

</style>