<template>
    <main>
        <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="initialize()"/>
            </wrapModal>
        </Transition>
     <div class="p-5">
        <h1 class="text-3xl text-center p-2 mt-[4.3rem]">{{ $t('RecieptMsg') }}</h1> 
        
        <div class="grid grid-cols-3 gap-2 max-sm:grid-cols-1">
            <div class="">
                    <div class="p-3">
                        <label for="" class="text-gray-500">{{$t('clientMsg')}}</label>
                        <select name="" id="" v-model="form.client_id"
                        class="select-buja-wide"> 
                        >
                            <option value=""></option>
                            <option  v-for="client in clients" :key="client.id" :value="client.id">
                                {{ client.name }} /
                                Tel : {{ client.telephone }}
                            </option>
                        </select>
                    </div>     
                    <div class="p-3">
                        <div>
                            <button class="p-1 bg-gray-400 flex gap-1 rounded-sm w-auto mt-3 mb-3" @click="toggleModal(),this.$store.state.serviceId=null">
                                <span class="material-icons">add</span>
                                <span>Service</span>
                            </button>
                        </div>
        
                       <label for="" class="text-gray-500"> Service :</label>
                        <select name="" id="" v-model="select_service"
                        class="select-buja-wide"> 
                        >
                            <option  v-for="service in serviceNotSelected" :value="service" :key="service.id">
                                 {{ service.name }} / Price : {{ service.amount }} #FBU
                            </option>
                        </select>
                    </div>
                    <div class="mt-3 float-right">
                        <button @click="addService" class="btn-green-add">{{$t('AddMsg')}}</button>
                    </div>
                                    
            </div>
            <!-- ... -->
            <div class="col-span-2 w-full ">
                <router-link to="facture_list" class=" ml-8 rounded-sm p-3 border-2 border-gray-800 hover:bg-gray-400">{{ $t('invoiceListMsg') }} </router-link>

            <div class="p-8 border-b border-gray-200  overflow-auto">
                    <table class="divide-y divide-gray-300 w-full">
                        <thead class="bg-black">
                            <tr>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t ('designationMsg') }}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{$t ('PriceMsg')}}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t('QuantityMsg') }}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    Total
                                </th>
                                <th class="px-6 py-2 text-xs text-white">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-300">
                            <tr v-for="(item, index) in form.servicesSelections" :key="item.id" class="text-center whitespace-nowrap">
                                <td class="px-6 py-4 text-sm text-gray-500 text-left">
                                    {{ item.name }}
                                </td>
                                <td class="px-6 py-4 text-right">
                                    <div class="text-sm text-gray-900">
                                        {{ formatNumber(item.amount) }}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        <input v-model="form.input_items[index]" @keyup="changeQuantite(index)"
                                        class="border-black border-2 focus:border-buja-primary w-[40px]"
                                        />
                                    </div>
                                </td>
                                <td class="px-6 py-4 text-right">
                                    <div class="text-sm text-gray-900 text-right">
                                        {{ formatNumber(form.input_items[index] * item.amount) }}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        <button @click="removeItem(item)" class="inline-block text-center">
                                            <span class="material-icons">{{$t ('deleteMsg')}}</span>
                                        </button>
                                    </div>
                                </td>

                            </tr>
                            <tr class="">
                                <td colspan="3" class="px-6 py-4 ">
                                    <div class="text-sm text-gray-900 font-bold"> TOTAL HTVA</div>
                                </td>
                                <td colspan="1" class="text-right px-6 py-4">
                                    <div class="text-sm text-gray-900 font-bold"> {{ formatNumber(total?.HTVA) }}</div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="px-6 py-4"> 
                                <div class="text-sm text-gray-900 font-bold">TVA</div>
                                </td>
                                <td>
                                <select name="" id="" v-model="selected_tva">
                                    <option value="0" selected>0%</option>
                                    <option value="4">4%</option>
                                    <option value="18">18</option>
                                </select>
                                </td>
                                <td colspan="1" class="text-right px-6 py-4"><div class="text-sm text-gray-900 font-bold">{{ formatNumber(total?.TVA)}}</div></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="3" class="px-6 py-4"><div class="text-sm text-gray-900 font-bold">TOTAL TVAC </div></td>
                                <td colspan="1" class="text-right px-6 py-4"><div class="text-sm text-gray-900 font-bold ">{{ formatNumber(total?.TVAC) }}</div></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="saveInvoince" class="btn-red-empty mt-3 float-right">{{$t ('downlaodMsg')}}</button>
                </div>
        </div>
    </div>
    
 </div>  
</main>
</template>

<script>

import api from '../../../api.js'
import result from '@/mixins/result';
import { mapMutations,mapState } from 'vuex';

export default {
    mixins : [result],
    data() {
        return {
            selected_tva : 0,
            form: {
                client_id: null,
                servicesSelections: [],
                input_items: [],
            },
            clients: [],
            services: [],
            select_service: null,
        };
    },
 
    mounted() {
        this.initialize();
    },
    components: {
             'formData':require('@/components/services/serviceForm.vue').default,
            'wrapModal':require('@/components/Global/wrapModal.vue').default,
        },
    methods: {
        ...mapMutations(['WRAP_MODAl','SIDE_BAR']),
            toggleModal(){
                this.WRAP_MODAl();
                console.log('clicked');
            },

        formatNumber(number) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'FBU' }).format(number);
        },
        changeQuantite(index) {
            this.form.servicesSelections[index]['quantite'] = this.form.input_items[index];
            this.form.servicesSelections[index]['total'] = this.form.input_items[index] *
            this.form.servicesSelections[index]['amount'];
        },
        saveInvoince() {
            api.post("factures", {
                data: this.form.servicesSelections,
                client_id: this.form.client_id,
                total: this.total,
                vat: this.selected_tva
            })
                .then(resp => {
                    //console.log(resp.data.msg)
                    this.successAllert(resp.data.msg);
                }).catch(err => {
                    console.log(err);
                });
        },
        removeItem(item) { 
            this.form.servicesSelections = this.form.servicesSelections.filter(it => it.id != item.id);
        },
        addService() { 
            this.form.servicesSelections.push(this.select_service)
            const index = this.form.servicesSelections.length - 1
            this.form.input_items[index] = 1;

            this.form.servicesSelections[index]['quantite'] = 1;

            this.form.servicesSelections[index]['total'] = this.form.input_items[index] *
                this.form.servicesSelections[index]['amount'];
            this.select_service = null;
        },
        optionDisplayText() {
            return "OptionDisplayText ";
        },
        initialize() {
            api.get("/client")
            .then(resp => {
                this.clients = resp.data;
            })
            .catch(err => {
                console.error(err);
            });
            api.get("/service")
            .then(resp => {
                this.services = resp.data;
            })
            .catch(err => {
                console.error(err);
            });
        },
      
    },
    computed: {
        
        ...mapState(['wrapModal','articleId','article']),
        total() { 
            
            let htva = 0;
            for (var i = 0; i < this.form.servicesSelections.length; i++) {

                htva += (this.form.servicesSelections[i].amount *1) *  this.form.input_items[i];
            }

             let tva = (htva * this.selected_tva / 100);
            return  {
                    HTVA: htva,
                    TVA: tva,
                    TVAC: (htva + tva),
            }
        },
        serviceNotSelected() {

            const idsSelected = [];

            for (const x of this.form.servicesSelections){
                idsSelected.push(x.id);
            }
            console.log(idsSelected);

            const items = this.services?.data?.filter(item =>
                !idsSelected.includes(item?.id))
            
            return items;
        },
    }
}
</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px); 
}



</style>