<template>
    <main>
            <div class="p-5 mt-[30px]">
                <div class="w-full">
                    <!-- <div class="flex mb-2">
                    <select name="" id="" v-model="invonce_type" class="py-2 px-1 border-black w-[200px] border-2 focus:border-buja-primary focus:outline-none mr-3 focus:shadow-[0px_1px_0_0_#004E71]">    
                        <option value=""></option>
                        <option value="0">{{ $t('pendingInvoiceMsg') }}</option>
                        <option value="1"> {{ $t('sentInvoiceMsg') }}</option>
                    </select>                 
                </div>        -->
                    <div v-if="status">
                        <Loading v-show="isLoading" class="h-full" />
                        <div class="mx-auto">
                            <div class="flex flex-col">
                                <div class="w-full">
                                    <div
                                        class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                                        <table class=" w-full" id="dataTable">
                                            <thead class="bg-black">
                                                <tr class=" border border-b">
                                                    <th class="px-6 py-2 text-xs text-white capitalize">#</th>
                                                    <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('clientMsg') }}
                                                    </th>
                                                    <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('clientMsg') }} N॰
                                                    </th>
                                                    <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('RecieptMsg') }}
                                                    </th>
                                                    <th class="px-6 py-2 text-xs text-white capitalize">HTVA</th>
                                                    <th class="px-6 py-2 text-xs text-white capitalize">TVA</th>
                                                    <th class="px-6 py-2 text-xs text-white capitalize">TVAC</th>
                                            
                                                    <th class="px-6 py-2 text-xs text-white capitalize">Facture Annulé</th>
                                            
                                                    <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('StateMsg') }}
                                                    </th>
                                                    <th class="px-6 py-2 text-xs text-white capitalize">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-300">
                                                <tr v-for="facult in facturesList" :key="facult.id"
                                                class="text-center whitespace-nowrap">
                                                <td class="px-6 py-4 text-sm text-gray-500">
                                                    {{ facult.id }}
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ facult.client?.name }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ facult.client?.id }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ facult.invoice_signature }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ formatNumber(facult.htva) }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ formatNumber(facult.tva) }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ formatNumber(facult.tvac) }}
                                                    </div>
                                            
                                                </td>
                                                <td class="px-6 py-4">
                                                    {{ facult.is_cancelled_at }}
                                                </td>
                                        
                                                <td class="px-6 py-4">
                                                    <div>
                                                        {{ facult.is_sent_to_obr }}</div>
                                                    </td>
                                            
                                                    <td class="px-6 py-4">
                                                        <div class=" flex gap-3 text-sm text-gray-900">
                                                            <button @click="detailFacture(facult.id)">
                                                                <span class="material-icons">print</span>
                                                            </button>
                                                    
                                                            <!-- <button v-if="!facult.is_sent_to_obr" @click="sentToObr(facult.id)">
                                                        Sent to OBR
                                                    </button> -->
                                                            <button v-if="!facult.is_sent_to_obr" @click="sentToObr(facult.id)"
                                                            class="flex border-black border-2 gap-1 rounded-sm p-2 w-auto">
                                                            <!-- <span>{{ $t('Send') }}</span>   -->
                                                            <span class="material-icons">send</span>
                                                        </button>
                                                
                                                        <button @click="cancelInvonce(facult.invoice_signature)">
                                                            cancel Invoince
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <tableHolder class="clear-both" />
                </div>
            </div>
        </div>
        </main>
</template>

<script>
import api from "../../api.js";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    data() {
        return {
            factures: [],
            invonce_type: "",
            isLoading: false,
            status: null
        }
    },
    components: {
        'Loading': require('@/components/Global/Loading.vue').default,
        'tableHolder': require('@/components/Global/tableSkeleton.vue').default,

    },
    watch: {
        factures() {
            $('#dataTable').DataTable().destroy();
            this.$nextTick(() => {
                $('#dataTable').DataTable(
                    {
                        "ordering": false
                    }
                )
            });
        },
    },
    mounted() {
        this.get();
    },
    methods: {
        cancelInvonce(invoice_signature) {
            // alert(invoice_signature)
            const motif = window.prompt("Entre le Motif d'annulation du Facture")
            if (motif) {
                this.isLoading = true;
                api.post('cancelInvoince', {
                    signature: invoice_signature,
                    cn_motif: motif,
                }).then(resp => {
                    alert(resp.data.msg)
                    this.isLoading = false;
                }).catch(err => {
                    alert(JSON.stringify(err))
                })
            }

        },
        sentToObr(invoice_id) {
            this.isLoading = true;
            api.get('send_invoice/' + invoice_id)
                .then(resp => {
                    alert(JSON.stringify(resp.data))
                    this.get();
                    this.isLoading = false;
                })
                .catch(err => {
                    console.log(err)
                })
        },

        synchronize() {
            this.isLoading = true;
            api.get('sent_pending_invoncies')
                .then(result => {
                    console.log(result)
                    this.isLoading = false;
                });
        },
        detailFacture(id) {
            console.log(id)
            this.$router.push({ path: 'detail_facture', query: { id: id } })
        },

        formatNumber(number) {

            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'FBU' }).format(number);
        },
        get() {
            api.get('factures')
                .then(resp => {
                    console.log(resp);
                    this.factures = resp.data
                    console.log("the status is ", resp.status);
                    if (resp.status === 200 || resp.status === 204) {
                        this.status = true
                    }
                    else {
                        this.status = false
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    },
    computed: {
        facturesList() {
            return this.invonce_type ? this.factures.filter(facture => facture.is_sent_to_obr = this.invonce_type) : this.factures
        }
    }
}
</script>

<style lang="scss" scoped></style>