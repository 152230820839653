<template>
    
    <div style=" margin: 120px 50px; width: 100%">
        
          <router-link :to="{name:'Location'}" style=" margin-top: 60px" class="nav-link collapsed" data-toggle="collapse" data-target="#collapseStock"
                                   aria-expanded="true" aria-controls="collapseStock">
                                     Listes des sorties 
          </router-link>
                        
     <div style="margin: 0 50px; width: 90%;">
                <div style=" padding: 20px; width: 80%;">
                    <img src="@/assets/bujaEvent.jpg" alt="" width="80px" height="50px">
                    <span style="color: gray;">L'innovation de l'evenement</span>
                    <div style="border-bottom: 4px solid gray; margin-left: 70px; margin-bottom: 5px;"></div>
                </div>

              <div>
                   <h1  class="facture-header" style="text-align: center; font-weight: bold;">Fiche de sortie du materiel</h1>
                <div style="display:flex; justify-content: space-between; border: 1px solid black; padding: 20px;">
                    <div class="nif-info">
                        <div>evenement: <span>{{ event.name }}</span></div>
                        <div>Nom du recepteur: <span>{{ client.name }}</span></div>
                        <div>Addresse: <span>{{ client.addresse }}</span></div>
                        <div>Tel: <span>{{ client.telephone }}</span></div>
                        
                    </div>
                    <div class="nif-info" >
                        <div>condition du travail: <span>{{ location.detail }}</span></div>
                        <div>Date de livraison: <span>{{ date }}</span></div>
                       
                        
                    </div>
                    
                </div>
                <div style="display:block;">
                        <h5>NB:lorem verifier orem verifier orem verifier orem verifierorem verifierorem verifier</h5>
                
                    </div>
              </div>
              <div style="padding-top: 50px;">
                <table style="width: 100%;">
                    <thead style="">
                        <tr>
                            <th>DESIGNATION</th>
                            <th> QUANTITE </th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in articles" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>{{ item.quantity }}</td>
                        </tr>
                        
                    </tbody>
                    
                </table>
              </div>
              <div class="footer" style="display:grid; margin:60px 50px ; grid-template-columns: 1fr 1fr;">
                <div>
                    <h6>signature du client:</h6>

                </div>
                <div>
                    <h6>Livraison autorisé par:</h6>

                </div>
                

              </div>
              
     </div>

    </div>
</template>

<script>
import api from '../../../api.js'
    export default {
        props:['id'],
        data()
        {
            return{
                location:[],
                articles:[],
                client:"",
                date:"",
                event:"",
            }

        },

        mounted()
        {
            this.getsortie(this.id);
        },

        methods:{
            getsortie(id)
        {
        api.get("/location/"+id)
        .then(resp => {
            this.location = resp.data[0]
            let client= JSON.stringify(resp.data[0].client)
            let events= JSON.stringify(resp.data[0].events)
            this.event=JSON.parse(events)
            this.client=JSON.parse(client)
            this.date=resp.data[0].nb_days.substr(0, 10)
           // let articles=JSON.stringify(resp.data[0].articles)
            this.articles=JSON.parse(resp.data[0].articles)
            console.log(this.articles)

        })
        .catch(err => {
            console.error(err)
        })


        },
        }
        
    }
</script>

<style  scoped>


 table, th, td {
      border: 1px solid black;
      border-collapse: collapse;
      

    }
    td{
        padding-left: 30px;

    }

    .nif-info{
        font-weight: bold;
        clear: both; 
    }
    .nif-info div{
        padding:10px;
    }

    .client-info{
        margin-top: 30px;
        margin-bottom: 20px;
    }

</style>