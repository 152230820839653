<template>  
  <div style="margin-top:70px;">
    <button @click="click" class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600">
              <span class="material-icons">keyboard_double_arrow_left</span>
    </button>

  </div>
  
    <div class="block  bg-gray p-6  dark:bg-neutral-700 " style="margin-top:140px;width:60%; height: 80%;">
       <!-- <div class="buja-form-content FormCl" ref="form-content"> -->
      <Loading v-show="loading" class="h-full"/>
       <!--<h1 class=" text-3xl flex justify-center mb-3 ">Article</h1>--> 
      
      <h1 class="  flex justify-left mt-5 ">Info:</h1> 
        <div class="mb-6"> 
            <div class="p-3">
              <div class=" mt-5">
                <p class="Py-2 mx-3">Recepteur:<span>{{ form.clientname }}</span></p>
                <p class="Py-2 mx-3">Event:<span>{{ form.events }}</span></p>
                <p class="Py-2 mx-3">Date delivré:<span>{{ form.date }}</span></p>
            </div>  
        </div> 
        <div style="border-bottom: 4px solid gray; margin-left: 0px; margin-bottom: 5px;"></div>
        
        <h1 class="flex justify-left mt-6 ">Equipment:</h1> 
        <div class="mt-2">
         <form action="" @submit.prevent="saveInformation" class=""   ref="ArticleForm">
          
          <table class="divide-y divide-gray-300 w-full">
                <thead class="p-3">
                   <tr>
                      <th>name</th>
                      <th>Quantity Sortie</th>
                      <th>Quantity Retour</th>
                      <th>Etat</th>
                      <th>commentaire</th>
                   </tr>
                </thead>
              <tbody class="bg-white divide-y divide-gray-300">
          
                  <tr v-for="(item,index) in articles"  :key="item.id" class="t-body">
                     <td class="p-3">
                       <h6> 
                          {{ item.name }}
                       </h6>
                     </td>
                     <td class="p-3">
                       <h6>
                           {{ item.quantity }}
                       </h6>
                     </td>
                     <td>
                     <input type="text" @keyup="onchange(index)"  placeholder="Quantit retour"  v-model="form.quantity[index]"
                        class="bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
                     </td>
                     <td>
                       <select name="" id=""  v-model="form.state[index]" class="pt-1 px-1 w-full border-b focus:border-buja-primary focus:outline-none ">
                       <option class="hover:bg-green-300">bad</option>
                       <option class="hover:bg-green-300">good</option>
                       <option class="hover:bg-green-300">notgood</option>
                     </select> 
                     </td>
                     <td>
                        <input type="text" @keyup="onchange(index)"   placeholder="commentaire"  v-model="form.comments[index]"
                           class=" bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
                     </td>
                  </tr>
                </tbody>
          </table> 
            <div class="flex gap-8 mt-5">
                <button type="submit" class=" rounded-sm p-3 bg-green-600 hover:bg-green-400">Enregister</button>
                <button type="reset" class=" rounded-sm p-3 bg-red-600 hover:bg-red-400">Empty</button>
            </div>
           
        </form>
         
    </div>  
    </div>
  </div>

    
</template>
<script>
import api from '../../../api.js'
import result from '@/mixins/result';


export default {
    props:['id'],
    mixins:[result],
    data()
    {
        return{
            form:{
                

                location_id:"",
                article_id:{},
                quantity:{},
                client_id:'',
                state:{},
                events:"",
                comments:{},
                clientname:"",
                date:"",
                inputs:[],
                articles:[],
            },
            location:[],
            articles:[],
            return:[],
        }
    },
    mounted()
    {
        //console.log( this.id )
        this.getsortie(this.id)
    },
    computed()
    {
      


    },
   
    methods:{
        onchange(index)
        {
           
                this.articles[index].quantityR=this.form.quantity[index]            
                this.articles[index].state=this.form.state[index]
                this.articles[index].comments=this.form.comments[index]
                console.log(this.articles)    


        },
        getsortie(id)
        {
        api.get("/location/"+id)
        .then(resp => {
            this.location = resp.data[0]
            this.$store.state.locationid=this.location
            this.form.location_id=this.location.id
            this.form.clientname= this.location.client.name
            this.form.events=this.location.events.name

            this.form.client_id=this.location.client.id
            this.form.date=this.location.nb_days.substr(0, 10);
            this.articles=JSON.parse(resp.data[0].articles)
            
            this.articles.forEach(function(item){item.quantityR = '', item.state='', item.comments=''});
            console.log(this.articles)
        })
        .catch(err => {
            console.error(err)
        })


        },
        click()
        {
          //this.$router.push('/Location')
          this.$router.go(-1)

        },
        
    
        saveInformation() { 
          let at=this.articles.filter((item)=>item.quantityR!=='' && item.state!=='' && item.comments!=='')
            this.form.articles=at  
          this.loading = true;
          console.log(at);
          if (this.form == {}) return;
          if (this.locationId == null) {
              api.post(
                "/return-article",
                this.form
              )
                .then((resp) => {
                  this.loading = false;
                  this.locations = resp.data;
                  this.successAllert()
                  this.form = { nb_days: new Date().toISOString().slice(0, 10), detail: "", service_id: "", articles: "", amount: "", client_id: "", status: "" }
                })
                .catch((err) => {
                  this.loading = false;
                  this.errorAlert()
                  console.error(err.response.data.errors);
                  this.errors = err.response.data.errors;
                 
                });
            } else {
              api.patch(
                "/return-article/" + this.locationId,
                this.form)
                .then((resp) => {
                  this.locations = resp.data;
                  this.loading = false;
                  this.successAllert()
                  this.$store.state.wrapModal = null;
                })
                .catch((err) => {
                  console.error(err.response.data.errors);
                  this.errors = err.response.data.errors;
                  this.errorAlert()
                });

            }

      },
        
    }

}
</script>

<style>

</style>