<template>
    <main>
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData/>
            </wrapModal> 
        </Transition>
    <div class="p-5">
        <h1 class="text-2xl text-center capitalize mt-20">{{ $t('TickeTypeMsg') }}</h1>
        <button class="btn-list w-auto" @click="toggleModal">{{ $t ('AddTicketTypeMsg') }}</button>			
        <!-- table -->
        <div v-if="status">
            <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full" id="dataTable">
                    <thead class="bg-black">
                        <tr class=" border border-b">
                            <th class="px-6 py-2 text-xs text-white">
                                #
                            </th>
                            <th class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('nameMsg') }}
                            </th>
                        
                            <th class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('actionMsg') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-300">
                        <tr v-for="ticketType in ticketTypes" :key="ticketType.id" class="text-center whitespace-nowrap">
                            <th scope="row" class="px-6 py-4 text-sm text-gray-500">
                                {{ ticketType.id }}
                            </th>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{ticketType.name}}
                                </div>
                            </td>
                            <td class="flex justify-center gap-8 px-6 py-4 whitespace-nowrap">
                                <button class="inline-block text-center" @click="ediTicketTypes(ticketType,ticketType.id),toggleModal1()">
                                    <span class="material-icons">edit_square</span>                                           
                                </button>
                                <button class="inline-block text-center" @click="deleteTicketTypes(ticketType.id)">
                                    <span class="material-icons">delete</span>
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 
        <div v-else>              
            <tableHolder/>
        </div>

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import result from '../../../mixins/result';
import api from '../../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    mixins: [result],

data () {     
    return {
        ticketTypes:[],
        status:null
    }
},
components:{
    'formData':require('./TicketTypeForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},


watch:{
    ticketTypes(val) {
          console.log(val)
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},
mounted(){
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
    this.$store.state.eventTicketId=null
},
toggleModal1()
{
    this.WRAP_MODAl();

},
fetchData() {
        api.get("/type-ticket")
        .then(resp => {
            this.ticketTypes = resp.data
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteTicketTypes(id) {
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
        api.delete("type-ticket/" + id)
        .then(resp => {
            this.showDeleteConfirmation()
            //this.ticketTypes = resp.data.data 
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }}
    )

        
    },

    ediTicketTypes(ticket,id){
        this.$store.state.TickeTypeId=id
        this.$store.state.TickeType=ticket
        
    
    }
},
computed:{
...mapState(['wrapModal']),
// ticketTypes(){
//         return  this.$store.state.ticketType
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    position: relative;
    z-index: 99; 
    transition: 0.8s ease all;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px);
}
</style>