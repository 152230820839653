import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import StockView from '../views/stockView.vue'
import CategoryView from '../views/categoryView.vue'
import Dashboard from '../views/Dashboard.vue'
import ArticleView from '../views/ArticleView.vue'
import ServiceView from '../views/serviceView.vue'
import ClientView from '../views/ClientView.vue'
import LocationView from '../views/LocationView.vue'
import RequisitionView from '../views/RequisitionView.vue'
import settingView from '@/views/settingView.vue'
import eventCategoryView from '@/views/EventCategoryView.vue'
import EventView from '@/views/EventView.vue'
import TickeTypeView from '@/views/TickeTypeView.vue'
import EventPriceView from '@/views/EventPriceView.vue'
import eventOrderView from '@/views/eventOrderView.vue'
import eventDetailView from '@/views/eventDetailView.vue'
import FactureView from '@/views/FactureView.vue'
import SortieView from '@/views/SortieView.vue'
import FactureList from '@/views/FactureList.vue'
import returnArticleForm from '@/components/returnarticles/returnform.vue'
import returnArticle from '@/components/returnarticles/returntables.vue'
import returnView from '@/components/returnarticles/viewreturn.vue'
import fichesortie from '@/components/locations/fichesortie.vue'
import FactureDetail from '@/views/FactureDetail.vue'
import usersView from '@/views/usersView.vue'
import viewreservation from '@/components/locations/viewsortie.vue'
import organizerView from '@/views/organizerView.vue'
import articleForm from '@/views/articleFormView.vue'
import LocationForm from '@/views/LocationFormView.vue'


const routes = [
  {
    path: "/login",
    name: "Login",
    component: ()=>import('@/components/Global/login.vue'),
    meta: {
      title:store.state.lacalLanguage==="en" ? "Aunthentication" : "Authentification "
    },
  }, 
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Dashboard" : "Tableau de Bord"
    },
  }, 
  {
    path: "/stock",
    name: "Stock",
    component: StockView,
    meta: {
      title:"Stock"
    },
  },
  {
    path: "/users",
    name: "users",
    component: usersView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "User" : "Utilisateur"
    },
  },
  {
    path: "/view/:id",
    name: "view",
    component: viewreservation,
    props: true,
    meta: {
      title:store.state.lacalLanguage==="en" ? "user role" : "Role d'un utilisateur"
    },
  },
  {
    path: "/fiche/:id",
    name: "fiche",
    component: fichesortie,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Output file" : "Fiche de sortie"
    },
    props: true
  },
  {
    path: "/Facture",
    name: "Facture",
    component: FactureView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Invoice" : "Facture"
    },
  },
  {
    path: "/detail_facture",
    name: "detail_facture",
    component: FactureDetail,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Invoice Details" : "Détails des Factures"
    },
  },
  {
    path: "/facture_list",
    name: "FacturList",
    component: FactureList,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Invoice List" : "Listes des Factures"
    },
  },
  {
    path: "/category",
    name: "Category",
    component: CategoryView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Category" : "Catégorie"
    },
  },
  {
    path: "/Sortie",
    name: "Sortie",
    component: SortieView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Output" : "Sortie"
    },
   
  },
  {
    path: "/article",
    name: "Article",
    component: ArticleView,
    meta: {
      title:"Article"
    },
  },
  {
    path: "/article_form",
    name: "Article_Form",
    component: articleForm,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Article Form" : "formulaire des Article"
    },
  },
  {
    path: "/service",
    name: "Service",
    component: ServiceView,
    meta: {
      title: "Service",
    },
  },
  {
    path: "/client",
    name: "Client",
    component: ClientView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Customer" : "Client"
    },
  },
  {
    path: "/location",
    name: "Location",
    component: LocationView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Rental" : "Location"
    },
  },
  {
    path: "/location_form",
    name: "Location_Form",
    component: LocationForm,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Rental Form" : "formulaire des locations"
    },
  },
  {
    path: "/return/:id",
    name: "returnid",
    component: returnArticleForm,
    meta: {
      title:store.state.lacalLanguage==="en" ? "return articles" : "Articles retournés"
    },
    props: true
  },
  {
    path: "/viewretun/:id",
    name: "viewretun",
    component: returnView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "return articles" : "Articles retournés"
    },
    props: true
  },
  {
    path: "/return",
    name: "return",
    component: returnArticle,
    meta: {
      title:store.state.lacalLanguage==="en" ? "return articles" : "Articles retournés"
    },
    
  },
  {
    path: "/requisition",
    name: "Requisition",
    component: RequisitionView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Requisition" : "Réquisition"
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: settingView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Settings" : "Paramétre"
    },
  },
  {
    path: "/eventCategory",
    name: "Event Category",
    component: eventCategoryView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Category" : "Catégorie d'évenément"
    },
  },
  {
    path: "/event",
    name: "Event",
    component: EventView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event" : "évenément"
    },
  },
  {
    path: "/ticket_type",
    name: "Ticket type",
    component: TickeTypeView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Ticket type" : "Type de ticket"
    },
  },
  {
    path: "/event_price",
    name: "Event Price",
    component: EventPriceView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Price" : "Prix d'évenément"
    },
  },
  {
    path: "/event_order",
    name: "Event Order",
    component: eventOrderView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Order" : "commande d'évenément"
    },
  },
  {
    path: "/event_detail",
    name: "Event Detail",
    component: eventDetailView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Detail" : "detaille d'évenément"
    },
  },
  {
    path: "/organiser",
    name: "Organizer",
    component: organizerView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Organizer" : "Organisateur"
    },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title } | Buja Event`;
  next();
});

export default router
