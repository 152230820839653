<template>
    <!-- <div :class="`${loading ? 'fixed p-[56px] bg-[rgb(20,22,37)] max-w-[700px] h-screen text-white ' : 'buja-form-content'}`" ref="form-content"> -->
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
       <!-- <div class="buja-form-content FormCl" ref="form-content"> -->
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3 ">Sortie article</h1>
        <form action="" @submit.prevent="saveInformation" ref="locationForm">
        <div class="  max-w-sm rounded overflow-hidden shadow-lg">
         <div>
            <div class=" p-3">
              <label for="">Nom du recepteur:</label>
              <select name="" id="" required v-model="form.client_id" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
                <option v-for="client in clients"  :key="client.id" :value="client.id" class="bg-[#141625] hover:bg-green-300">
                  {{ client.name }}
                </option>
              </select>              
            </div>
          <!--  <div class=" p-3">
              <label for="">Address</label>
              <textarea placeholder="" v-model="form.address"
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> </textarea>
               <div>{{ errors?.address }}</div>
                           
            </div>
            <div class=" p-3">
              <label for="">Tel:</label>
              <input type="number" required  v-model="form.tel"
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.tel }}</div> 
            </div> -->
        </div>
        <div>
        
            <div class=" p-3">
                <label for="">date de livraison</label>
              <input type="date" required placeholder="Amount" v-model="form.nb_days"
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.amount }}</div> 
            </div>
         

            <div class=" p-3">
              <label for="">condition de travail:</label>
              <input type="text" required placeholder="Detail" v-model="form.detail"
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
              
               <div>{{ errors?.condition }}</div> 
            </div>
            </div>
            
            <div class="p-3">
              <label for="">Status</label>
              <select name="" id="" v-model="form.status" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
                <option class="hover:bg-green-300"> pending </option>
                <option class="hover:bg-green-300"> done </option>
              </select> 
            </div>
        </div>
        <div class="pt-5 max-w-sm rounded overflow-hidden shadow-lg">
          <h5>Equipment</h5>
          <div class="flex justify-between">
            <div class=" p-3">
              <label for="">Designation:</label>
              <select name=""  id="" v-model="form.article_id" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
                <option v-for="article in articles"  :key="article.id" :value="article" class="hover:bg-green-300">
                  {{ article.name}}
                </option>
              </select>              
            </div> 
            <div class="p-3">
              <label for="">quantity:</label>
              <input type="number"  placeholder="quantity" v-model="form.amount"
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.amount }}</div> 
            </div>
            <div>
              
                <span @click="addNewDetail" class="cursor-pointer border-2 border-white text-white  font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1">Add</span>
              
            </div>
          </div>
          <div class=" p-3"  v-for="item in autres" :key="item.id">
          <div class="flex justify-between">
            <div>
              <h6>name</h6>
              <h6>Quantity</h6>

            </div>
            <div>
              <h6>{{ item.name }}</h6>
              <h6>{{ item.quantity }}</h6>
              
            </div>
            <button @click="deleteDetail(item.id)" class="cursor-pointer outline-none border-none text-white mr-[8px] font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1 mt-2">X</button>
            </div>
            </div>
        </div>
           
     
            
            <div class="flex gap-8 mt-5">
                <button type="submit" class=" rounded-sm p-3 bg-green-600 hover:bg-green-400">{{saveEditBtn}}</button>
                <button type="reset" class=" rounded-sm p-3 bg-red-600 hover:bg-red-400">Empty</button>
              </div>
        </form>
        
         
    </div>   
  </template>
  
  <script>
  
  import { mapMutations,mapState } from 'vuex';
  import {uid} from 'uid'
  import api from '../../../api.js'
  import result from '../../mixins/result';
  export default{
    mixins:[result],
        data(){
            return{
            form:{
                status:'',
                client_id:'',
                nb_days: new Date().toISOString().slice(0, 10),
                articles:'',
                detail:'',
                article_id:'',
                amount:''
    
                // autres:[]

            },
            loading:null,
            autres:[],
            clients:[],
            articles:[],
            errors:[],
            saveEditBtn: "save",
            }
        },
        components:{
            'Loading':require('../Global/Loading.vue').default
        },
      /*  watch:{
          "$store.state.articleId"(a){
            if(this.$store.state.articleId==null){
              this.form={};
                this.saveEditBtn="Save"
              }else{
                this.form=this.$store.state.article;
                this.form.category_id=this.$store.state.article.category_id;
                this.saveEditBtn="Edit"
              }
                console.log(a)
          }
        },*/
        mounted()
        {
           this.getclient()

           this.getarticles()
           if(this.$store.state.articleId==null){
              this.form={};
              this.form.uuid=uid(),
                this.saveEditBtn="Save"
              }else{
                this.form=this.$store.state.article;
                this.form.category_id=this.$store.state.article.category_id;
                console.log(this.$store.state.article.category_id)
                console.log(this.form.category_id)
                this.saveEditBtn="Edit"
              }
  
  
        },
        computed:{
        ...mapState(['articleId','article'])
    },
  
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();
                    this.$emit('close')   
                },
                SaveData(){
                    this.loading=true;
                },
                getarticles() {
                    api.get("/article")
                    .then(resp => {
                        this.articles = resp.data
                        console.log(this.services)
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
                getclient() {
                    api.get("/client")
                    .then(resp => {
                        this.clients = resp.data
                        console.log(this.clients)
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
            saveInformation() {            
            this.loading = true;
           // console.log(this.form.uuid);
            if (this.form == {}) return;
            // console.log('please complete required fields')
            this.form.articles=this.autres
  
            if (this.locationId == null) {
                api.post(
                  "/location",
                  this.form
                )
                  .then((resp) => {
                    this.loading = false;
                    this.locations = resp.data;
                    this.successAllert()
                    this.form = { nb_days: new Date().toISOString().slice(0, 10), detail: "", service_id: "", articles: "", amount: "", client_id: "", status: "" }
                  })
                  .catch((err) => {
                    this.loading = false;
                    console.error(err.response.data.errors);
                    this.errors = err.response.data.errors;
                    this.errorAlert()
                  });
              } else {
                api.patch(
                  "/location/" + this.locationId,
                  this.form)
                  .then((resp) => {
                    this.locations = resp.data;
                    this.loading = false;
                    this.successAllert()
                    this.$store.state.wrapModal = null;
                  })
                  .catch((err) => {
                    console.error(err.response.data.errors);
                    this.errors = err.response.data.errors;
                    this.errorAlert()
                  });

              }
  
        },



addNewDetail() {
let result= this.autres.filter((item)=>item.id==this.form.article_id.id);
let result2= this.articles.find((item) => item.id == this.form.article_id.id)
        
            console.log(result2)
if(result.length==0)
{
if(this.form.amount>result2.quantity)
{
    alert('quantité pas disponible')
}
else{
    this.autres.push({
    
    id: this.form.article_id.id,
    name:this.form.article_id.name,
    quantity: this.form.amount,
  });

}
  console.log(this.autres)    
}
},

  deleteDetail(id) {
    this.autres = this.autres.filter((item) => item.id !== id);
  },


        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }

  </style>