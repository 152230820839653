<template>
    <main>
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
        </Transition>
    <div class="p-5">
        <button class="btn-list mt-20" @click="toggleModal">{{$t ("AddMsg")}} {{ $t ('clientMsg') }}</button>			
        <!-- table -->
        <div v-if="status">
            <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full" id="datatable">
                    <thead class="bg-black">
                        <tr>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                #
                            </th>
                            <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('nameMsg') }}
                            </th>
                                    <!-- <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                {{$t ('companyNutMsg')}}
                            </th> -->
                                    <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                        {{ $t('telephoneMsg') }}
                                    </th>
                                    <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                        {{ $t('addressMsg') }}
                                    </th>
                                    <th scope="col"  class="px-6 py-2 text-xs text-white capitalize">
                                        {{ $t('CustomerMsg') }}
                                    </th>
                                    <th class="px-6 py-2 text-xs text-white capitalize">
                                        VAT
                                    </th>                             
                                    <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-300">
                                <tr v-for="client in clients" :key="client.id" class="text-center whitespace-nowrap">
                                    <th scope="row" class="px-6 py-4 text-sm text-gray-500">
                                        {{ client.id }}
                                    </th>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            {{ client.name }}
                                        </div>
                                    </td>
                                        <!-- <td class="px-6 py-4 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                            {{ client.company_nature }}
                                </div>
                            </td> -->
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-sm text-gray-900 ">
                                        {{ client.telephone }}
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-sm text-gray-900">
                                        {{ client.addresse }}
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-sm text-gray-900">
                                        {{ client.customer_TIN }}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900 whitespace-nowrap">
                                        {{ client.vat_customer_payer }}
                                    </div>
                                </td>
                        
                                <td class="flex justify-center gap-8 px-6 py-4 whitespace-nowrap">
                                    <button class="inline-block text-center " @click="editClient(client, client.id), toggleModal()">
                                        <span class="material-icons">edit_square</span>                                           
                                    </button>
                                    <button class="inline-block text-center" @click="deleteClient(client.id)">
                                        <span class="material-icons">delete</span>
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
             </div>
             <!-- <div v-if="status">

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Product name
                </th>
                <th scope="col" class="px-6 py-3">
                    Color
                </th>
                <th scope="col" class="px-6 py-3">
                    Category
                </th>
                <th scope="col" class="px-6 py-3">
                    Price
                </th>
                <th scope="col" class="px-6 py-3">
                    <span class="sr-only">Edit</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Apple MacBook Pro 17"
                </th>
                <td class="px-6 py-4">
                    Silver
                </td>
                <td class="px-6 py-4">
                    Laptop
                </td>
                <td class="px-6 py-4">
                    $2999
                </td>
                <td class="px-6 py-4 text-right">
                    <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Microsoft Surface Pro
                </th>
                <td class="px-6 py-4">
                    White
                </td>
                <td class="px-6 py-4">
                    Laptop PC
                </td>
                <td class="px-6 py-4">
                    $1999
                </td>
                <td class="px-6 py-4 text-right">
                    <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Magic Mouse 2
                </th>
                <td class="px-6 py-4">
                    Black
                </td>
                <td class="px-6 py-4">
                    Accessories
                </td>
                <td class="px-6 py-4">
                    $99
                </td>
                <td class="px-6 py-4 text-right">
                    <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

          </div> -->
             <div v-else>              
                <tableHolder/>
            </div> 

        </div>
    </main>  
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import api from '../../../api.js'
import result from '@/mixins/result';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    mixins: [result],
    data() {
        return {
            clients: [],
            status: null
        }
    },
components:{
    'formData':require('./clientForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},
watch:{
    clients(val) {
          console.log(val)
          $('#datatable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#datatable').DataTable()
          });
        },
},
mounted(){
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
},
fetchData() {
        api.get("/client")
        .then(resp => {
            this.clients = resp.data
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteClient(id) {
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
        api.delete("client/" + id)
        .then(resp => {
            this.showDeleteConfirmation()
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
        }}
    )},

    editClient(cli,id){
        this.$store.state.clientId=id
        this.$store.state.client=cli
    
    }
},
computed:{
...mapState(['wrapModal','clientId','client']),
// clients(){
//         return  this.$store.state.client
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99; 
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    transform: translateX(700px);
    position: relative;
    z-index: 99; 
}
</style>